<template>
  <div
    class="cover-wrapper"
    @touchend="moving = false"
    v-loading="isLoading"
    element-loading-text="场馆刷新中"
  >
    <el-button
      type="text"
      class="back-button"
      icon="el-icon-arrow-left"
      @click="$router.replace({ name: 'BuyTicket', query: $route.query })"
      style="position: absolute;z-index: 2;"
    ></el-button>
    <div class="mini-map" v-if="showMiniMap">
      <!-- <ul v-for="(item, i) in arr" class="rows" :key="i">
        <li v-for="(item2, i2) in item" class="seat-item" :key="i2"></li>
      </ul> -->
      <div
        class="mini-map-wrap"
        ref="miniMap"
        :style="`height: ${mapHeight + 2}px`"
      >
        <div
          class="seat-box"
          :style="
            `width: ${containerWidth}px; height: ${containerHeight}px; transform: scale(${widthCalc});transform-origin: 0 0;zoom: 1;`
          "
        >
          <ul
            v-for="(item, i) in arr"
            class="rows"
            :class="[{ side: item.isSide }]"
            :style="
              `background-color: ${!item.isSide && areaColors[item.area]}`
            "
            :key="i"
          >
            <template v-for="(item2, i2) in item.cols">
              <li
                v-if="!item2.isSide"
                class="seat-item"
                :class="{
                  checked: item2.checked,
                  unchecked: !item2.checked,
                  disabled: item2.disabled,
                  solded: item2.solded,
                  opacity0: !item2.seatId
                }"
                :key="i2"
              ></li>
              <li v-else class="side" :key="i2"></li>
            </template>
          </ul>
        </div>
      </div>
      <div
        class="loop"
        :style="
          `width: ${(scaleWidth * mapWidth) / zoom}px;
          height: ${(scaleHeight * mapHeight) / zoom}px;
          margin-left: ${(scrollLeft * scaleMoveX) / zoom}px;
          margin-top: ${(scrollTop * scaleMoveY) / zoom}px;`
        "
        @touchstart="touchstartHandle"
        @touchmove="touchmoveHandle"
      ></div>
    </div>
    <div class="venue-box">
      <div class="bay-number" ref="bayNumber">
        <div
          class="bay"
          :class="{ side: item.isSide }"
          v-for="(item, i) in arr"
          :style="`zoom: ${zoom}`"
          :key="i"
        >
          {{ item.isSide ? "" : getIndex(i) }}
          <span v-if="item.isSide"
            >{{ item.area }}：{{ item.number.value }}</span
          >
        </div>
      </div>
      <div class="seat-wrap" ref="seatWrap" @scroll="scrollHandle">
        <div
          class="seat-box"
          ref="seatBox"
          id="seatBox"
          :style="
            `width: ${containerWidth}px; height: ${containerHeight}px; zoom: ${zoom}`
          "
        >
          <template v-for="(item, i) in arr">
            <ul
              class="rows"
              :class="[{ side: item.isSide }]"
              :style="
                `background-color: ${!item.isSide && areaColors[item.area]}`
              "
              :key="i"
            >
              <template v-for="(item2, i2) in item.cols">
                <li
                  v-if="!item2.isSide"
                  class="seat-item"
                  :class="{
                    checked: item2.checked,
                    unchecked: !item2.checked,
                    disabled: item2.disabled,
                    solded: item2.solded,
                    opacity0: !item2.seatId
                  }"
                  :key="i2"
                  @click="seatChangeHandle(item2)"
                ></li>
                <li v-else class="side" :key="i2"></li>
              </template>
            </ul>
          </template>
        </div>
      </div>
    </div>
    <div class="ticket-info">
      <ul class="legend-wrap">
        <li class="unchecked">可选</li>
        <li class="checked">已选</li>
        <li class="solded">已售</li>
        <li class="disabled">不可选</li>
      </ul>
      <div class="activity-wrap">
        <div class="header">
          <div class="title">{{ activity.name }}</div>
          <i
            class="el-icon-arrow-down"
            :class="{
              expand: isExpand
            }"
            @click="isExpand = !isExpand"
          ></i>
        </div>
        <div class="info">
          <span v-if="activity.metadata"
            >{{ activity.metadata.signInStartTime }}-{{
              this.activity.metadata.signInEndTime
            }}</span
          >
          <!-- <span>今天</span>
          <span>6-20</span>
          <span>17:00-19:00</span> -->
          <span>{{ activity.address }}</span>
        </div>
        <div class="check-seat-wrap" :class="{ expand: isExpand }">
          <div class="check-seat-scroll-wrap">
            <div
              class="seat-ticket"
              v-for="(item, i) in checkedList"
              :key="i"
              @click="item.checked = false"
            >
              <div class="title">
                {{ item.area }} {{ item.row }}排{{ item.col }}座
              </div>
              <div class="price">￥ {{ item.price }}</div>
              <i class="el-icon-close"></i>
            </div>
          </div>
        </div>
      </div>
      <el-button
        v-if="checkedList.length"
        type="warning"
        round
        @click="commitHandle"
        >￥{{ totalPrice }}
        <span class="origin-price">￥{{ originPrice }}</span>
        确认选座</el-button
      >
      <el-button v-else type="warning" disabled round>请先选座</el-button>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import Mixin from "./mixin.js";
import { setGesture } from "@/libs/util.js";
import {
  getTsRoomLayoutPage,
  getTsTicketSeats,
  getTsRoomArea
} from "@/api/index.js";

export default {
  props: {
    seats: {
      type: Array,
      default: () => []
    }
  },
  mixins: [Mixin],
  data() {
    // let arr = Array.from(new Array(11)).fill(
    //   Array.from(new Array(10)).fill('{"checked":false}')
    // );
    // arr = arr.map((v, i) => {
    //   return v.map((v2, i2) => ({
    //     ...JSON.parse(v2),
    //     price: Math.floor(Math.random() * 100),
    //     row: i,
    //     col: i2
    //   }));
    // });

    return {
      base64checked:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAXAgMAAABsT2e+AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAMUExURUdwTGucJim4Cw7DADyeV/gAAAADdFJOUwCH+6Tb4cYAAAAJcEhZcwAAAEgAAABIAEbJaz4AAAAjSURBVAjXY5D+DwRPGOpB1F+G/SDqH8N/MKA7BbUd6haIywAaEoXz03YkiAAAAABJRU5ErkJggg==",
      base64uncheck:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAXBAMAAADjD5IeAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAVUExURUdwTN7e3t7e3t7e3t7e3v///9/f3yET2RIAAAAFdFJOUwBR3N5JJfKzhwAAAAlwSFlzAAAASAAAAEgARslrPgAAACpJREFUGNNjYFRLg4JkAQaxUDhIYVBFcIIYUhGcsGHHQfEpShgwmiFCBwCBYlusFfUg8wAAAABJRU5ErkJggg==",
      base64solded:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAXAgMAAABsT2e+AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAMUExURUdwTPNiXvNiXvNiXnJVmRMAAAADdFJOUwBP3XX1OX0AAAAJcEhZcwAAAEgAAABIAEbJaz4AAAAjSURBVAjXY5D+DwRPGOpB1F+G/SDqH8N/MKA7BbUd6haIywAaEoXz03YkiAAAAABJRU5ErkJggg==",
      base64disabled:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAXBAMAAADjD5IeAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAPUExURUdwTN7e3t7e3uzs7N/f38hsf+gAAAADdFJOUwBP3XX1OX0AAAAJcEhZcwAAAEgAAABIAEbJaz4AAAAoSURBVBjTY2BUcYECJwEGEWM4cGRQRnCMGJwRHJNhx0HxKUoYIIcOAN0JOECEhR4dAAAAAElFTkSuQmCC",
      seatWidth: 23,
      seatHeight: 23,
      seatMarginCol: 2,
      seatMarginRow: 5,
      seatLineHeight: 30,
      seatLineWidth: 30,
      halfWidth: 0,
      halfHeight: 0,
      arr: [],
      canvas1: null,
      ctx: null,
      wrapWidth: 0,
      wrapHeight: 0,
      scrollLeft: 0,
      scrollTop: 0,
      miniMapWidth: 0,
      miniMapHeight: 0,
      miniMap: null,
      showMiniMap: false,
      moving: false,
      marginLeft: 0,
      marginTop: 0,
      isExpand: true,
      isLoading: true,

      seatsStatus: {},
      activity: {},
      areaColors: {},

      zoom: 1,
      boxGesture: null,
      ticketCategory: "",
    };
  },
  computed: {
    scaleWidth() {
      return this.wrapWidth / this.containerWidth;
    },
    scaleHeight() {
      return this.wrapHeight / this.containerHeight;
    },
    scaleMoveX() {
      return this.mapWidth / this.containerWidth;
      // return this.miniMapWidth / this.containerWidth;
    },
    scaleMoveY() {
      return this.mapHeight / this.containerHeight;
      // return this.miniMapHeight / this.containerHeight;
    },
    containerWidth() {
      let max = 0;
      this.arr.forEach(v => {
        max = v.cols.length > max ? v.cols.length : max;
      });
      return (this.seatWidth + this.seatMarginCol * 2) * max + 100;
    },
    containerHeight() {
      let max = this.arr.length;
      return (this.seatHeight + this.seatMarginRow * 2) * max;
    },
    checkedList() {
      let arr = [];
      this.arr.forEach(v => {
        v.cols.forEach(v2 => {
          v2.checked && arr.push(v2);
        });
      });
      return arr;
    },
    originPrice() {
      let total = 0;
      this.checkedList.forEach(v => {
        total += v.price * 10000;
      });

      return (total / 10000).toFixed(2);
    },
    totalPrice() {
      let total = 0;
      this.checkedList.forEach(v => {
        if (this.checkedList.length >= this.discountCondition) {
          total += v.price * (this.discount / 10) * 10000;
        } else {
          total += v.price * 10000;
        }
      });

      return (total / 10000).toFixed(2);
    },
    mapWidth() {
      return window.document.body.clientWidth * 0.3;
    },
    mapHeight() {
      return this.containerHeight * this.widthCalc + 10;
    },
    widthCalc() {
      return (this.mapWidth - 10) / this.containerWidth;
    }
    // activity() {
    //   return this.$store.state.normal.activity || {};
    // }
  },
  watch: {
    containerWidth: {
      handler(e) {
        this.$nextTick(() => {
          this.$refs.seatWrap.scrollLeft = e / 2 - this.wrapWidth / 2;
        });
      },
      immediate: true
    }
  },
  created() {
    this.discountCondition = this.$route.query.discountCondition;
    this.discount = this.$route.query.discount;
    this.ticketCategory = this.$route.query.ticketCategory;
  },
  mounted() {
    this.initGesture();
    // this.initImg();
    // this.init();
    // let timer = null;
    // window.onresize = () => {
    //   clearTimeout(timer);
    //   timer = setTimeout(() => {
    //     this.renderAll();
    //   }, 10);
    // };
  },
  methods: {
    initGesture() {
      this.boxGesture = setGesture(this.$refs.seatWrap);

      this.boxGesture.gesturemove = e => {
        this.zoom = e.scale;
      };
    },
    async getTsRoomArea() {
      await getTsRoomArea({
        params: {
          page: 1,
          limit: 10000
        }
      }).then(({ code, data }) => {
        if (code === 0) {
          data.forEach(v => {
            this.areaColors[v.name] = v.color;
          });
        }
      });
    },
    async callback() {
      await this.getTsRoomArea();
      await this.getTsTicketSeats();
      await this.getTsRoomLayoutPage();
    },
    getIndex(i) {
      let active = this.arr[i];
      let filter = this.arr.filter(v => !v.isSide);
      return filter.indexOf(active) + 1;
    },
    async getTsTicketSeats() {
      await getTsTicketSeats({
        params: {
          ticketId: this.activity.id,
          roomId: this.activity.roomId,
          page: 1,
          limit: 10000
        }
      }).then(({ code, data }) => {
        if (code === 0) {
          let obj = {};
          data.forEach(v => {
            obj[`${v.rowNum}_${v.colNum}`] = v;
          });
          this.seatsStatus = obj;
        }
      });
    },
    async getTsRoomLayoutPage() {
      await getTsRoomLayoutPage({
        params: {
          roomId: this.activity.roomId,
          ticketId: this.activity.id,
          page: 1,
          limit: 10000
        }
      }).then(({ code, data }) => {
        if (code === 0) {
          let area = "";
          let obj = {};
          data.forEach(v => {
            if (area !== v.area) {
              obj = {
                value: 0
              };
              // v.isSide = true;
              this.arr.push({
                isSide: true,
                cols: [],
                area: v.area,
                number: obj
              });
            }
            area = v.area;
            v.cols = [];

            let col = 0;
            v.colSplits.forEach((v2, j) => {
              obj.value += ~~v2;
              if (j) {
                v.cols.push({
                  isSide: true
                });
              }
              for (var i = 0; i < v2; i++) {
                col++;
                let obj = this.seatsStatus[`${v.rowNum}_${col}`] || {};

                let seatIds = this.seats.map(v => v.seatId);

                // 如果选择的票种不是VIP，则该区域的座位不可选；如果选择的票种不是普票，则该区域的座位不可选
                if (obj.status !== "USED" 
                    && (this.ticketCategory === "VIP" && v.area.indexOf("VIP") === -1
                      || (this.ticketCategory !== "VIP" && v.area.indexOf("VIP") !== -1))) {
                  obj.status = "UNAVAILABLE";
                }

                v.cols.push({
                  row: v.rowNum,
                  checked: seatIds.includes(obj.id),
                  price: +obj.price,
                  area: v.area,
                  col: col,
                  roomId: v.roomId,
                  id: v.id,
                  seatId: obj.id,
                  disabled: ["PRE_USED", "UNAVAILABLE", undefined].includes(obj.status),
                  solded: obj.status === "USED",
                  status: obj.status
                });
              }
              // v.cols[v.cols.length - 1].isSide = true;
            });
            this.arr.push(v);

            this.$nextTick(() => {
              // this.initMiniMap();
              this.showMiniMap = true;
              this.isLoading = false;
              this.initSize();
            });
          });
        }
      });
    },
    initMiniMap() {
      html2canvas(this.$refs.seatBox, {
        backgroundColor: "transparent"
      }).then(canvas => {
        this.showMiniMap = true;

        this.$nextTick(() => {
          this.$refs.miniMap.innerHTML = "";
          this.$refs.miniMap.appendChild(canvas);

          this.miniMapHeight = this.$refs.miniMap.clientHeight;
          this.miniMapWidth = this.$refs.miniMap.clientWidth;

          this.isLoading = false;
        });
      });
    },
    initSize() {
      this.wrapWidth = this.$refs.seatWrap.clientWidth;
      this.wrapHeight = this.$refs.seatWrap.clientHeight;
    },
    scrollHandle(e) {
      this.scrollLeft = e.target.scrollLeft;
      this.scrollTop = e.target.scrollTop;

      this.$refs.bayNumber.scrollTop = this.scrollTop;
    },
    touchstartHandle(e) {
      this.moving = true;

      this.marginLeft = e.touches[0].screenX;
      this.marginTop = e.touches[0].screenY;
    },
    touchmoveHandle(e) {
      if (!this.moving) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();

      let offsetX = e.touches[0].screenX - this.marginLeft;
      let offsetY = e.touches[0].screenY - this.marginTop;

      this.$refs.seatWrap.scrollLeft += offsetX / this.scaleMoveX;
      this.$refs.seatWrap.scrollTop += offsetY / this.scaleMoveY;

      this.marginLeft = e.touches[0].screenX;
      this.marginTop = e.touches[0].screenY;
    },
    seatChangeHandle(e) {
      if (e.status === "FREE") {
        e.checked = !e.checked;
      }

      // this.$nextTick(() => {
      //   this.initMiniMap();
      // });
    },
    commitHandle() {
      this.$emit("resolve-seat", this.checkedList);
      this.$router.replace({
        name: "BuyTicket",
        query: this.$route.query
      });
    },

    init() {
      // this.$refs.seatBox
      let canvas1 = document.createElement("canvas");
      this.canvas1 = canvas1;
      let position = this.$refs.seatBox.getBoundingClientRect();

      canvas1.width = position.width;
      canvas1.height = position.height;

      this.ctx = canvas1.getContext("2d");

      this.$refs.seatBox.appendChild(canvas1);

      setTimeout(() => {
        this.renderAll();
      });
    },
    initImg() {
      // 初始化图标
      let img = new Image();
      img.src = this.base64checked;
      img.onload = () => {
        this.base64checkedImg = img;
      };

      let img2 = new Image();
      img2.src = this.base64uncheck;
      img2.onload = () => {
        this.base64uncheckImg = img2;
      };

      let img3 = new Image();
      img3.src = this.base64solded;
      img3.onload = () => {
        this.base64soldedImg = img3;
      };

      let img4 = new Image();
      img4.src = this.base64disabled;
      img4.onload = () => {
        this.base64disabledImg = img4;
      };
    },
    renderAll() {
      this.canvas1.width = this.$refs.seatBox.clientWidth;

      this.halfHeight = this.$refs.seatBox.clientHeight / 2;
      this.halfWidth = this.$refs.seatBox.clientWidth / 2;

      let lineLen = this.arr.length / 2;
      this.arr.forEach((v, i) => {
        let y = this.halfHeight - lineLen * this.seatLineHeight;
        y += i * this.seatLineHeight;

        let rowLen = v.length / 2;
        v.cols.forEach((v2, i2) => {
          let x = this.halfWidth - rowLen * this.seatLineWidth;
          x += i2 * this.seatLineWidth;

          this.drawSeat(x, y);
        });
      });
    },
    drawSeat(x, y) {
      this.ctx.drawImage(
        this.base64disabledImg,
        x - this.seatWidth / 2,
        y - this.seatHeight / 2
      );
    }
  }
};
</script>

<style lang="stylus" scoped>
.cover-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f6f6f6;
  z-index: 100;
  overflow: auto;
  .mini-map {
    width: 30vw;
    // height: 30vh;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, .2);
    background-image: url('../assets/stage.png');
    background-repeat: no-repeat;
    background-size: 70% auto;
    background-position: center 4px;

    // opacity: .2;
    pointer-events: none;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    // padding: 5px;
    padding-top: 8vw;

    .mini-map-wrap {
      position: relative;
      padding: 5px;
      overflow: hidden;
      box-sizing: border-box;
    }

    .loop {
      max-width: 100%;
      max-height: calc(100% - 8vw);
      pointer-events: auto;
      border: 2px solid #ee3846;
      box-sizing: border-box;
      border-radius: 5px;
      position: absolute;
      left: 0;
      top: 8vw;
      opacity: .8;
    }

    // transform-origin: left top;
    // transform: scale(0.15, 0.15);
    /deep/ canvas {
      display: block;
      width: 100%!important;
      height: auto!important;

      opacity: 0.4;
    }
  }
  .venue-box {
    position: relative;
    height: calc(100% - 240px);
    background-image: url('../assets/stage.png');
    background-repeat: no-repeat;
    background-size: 70% auto;
    background-position: center 10px;
    padding-top: 32vw;
    box-sizing: border-box;

    &::before {
      position: absolute;
      left: 0;
      right: 0;
      content: '舞台';
      font-size: 1.2em;
      color: #a3a3b3;
      display: block;
      text-align: center;
      margin-top: -25vw;
    }

    .bay-number {
      pointer-events: none;
      position: absolute;
      width: 1000px;
      // border-radius: 10px;
      // background-color: rgba(91, 91, 91, .35);
      right: 10px;
      color: #ffffff;
      overflow: auto;
      height: calc(100% - 22vw);
      line-height: 23px;

      overflow: auto;
      // background-color: #f6f6f6;f6f6f6

      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar { width: 0 !important; height: 0 !important; }
      .bay {
        width: 22px;
        // box-sizing: border-box;
        background-color: rgba(91, 91, 91, .35);
        height: 23px;
        padding: 5px 0;
        text-align: center;
        margin-left: auto;
        &:first-child {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
        &:last-child {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        &.side {
          height: 40px;
          box-sizing: border-box;
          span {
            white-space: nowrap;
            margin-left: -100px;
            // text-shadow: 0 0 3px rgba(0, 0, 0, .5);
            background-color: rgba(0, 0, 0, .2);
            padding: 2px 4px;
            border-radius: 10px;
          }
        }
      }
    }

  }

  .seat-wrap {
    overflow: auto;
    // background-color: #f6f6f6;f6f6f6

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar { width: 0 !important; height: 0 !important; }
  }

  .seat-wrap,
  .seat-box {
    width: 100%;
    height: 100%;
  }
  .seat-box {
    min-width: 100%;
    // background-color: #f6f6f6;
    ul.side {
      // margin-top: 40px;
      height: 40px;
      // background-color: #cccccc;
    }
    li.side {
      // margin-right: 40px;
      width: 40px;
      // background-color: #cccccc;
    }
  }



  .rows {
    // width: 1000px;
    // margin-left: calc(50% - 500px);
    display: flex;
    justify-content: center;
    // &.level0 {
    //   background-color: rgba(255, 0, 0, .1);
    // }
    // &.level1 {
    //   background-color: rgba(255, 165, 0,.1);
    // }
    // &.level2 {
    //   background-color: rgba(255, 255, 0, .1);
    // }
    // &.level3 {
    //   background-color: rgba(0, 255, 0, .1);
    // }
  }
  .seat-item {
    width: 23px;
    height: 23px;
    box-sizing: border-box;
    border-radius: 3px;
    margin: 5px 2px;
    flex-shrink: 0;
    background-color: #ffffff;
    &.checked {
      background-color #0ec300;
      border-color: #0ec300;
    }
    &.unchecked {
      background-color #ffffff;
      border: 1px solid #eeeeee;
    }
    &.disabled {
      // background-color #ececec;
      // border-color: #dedede;
      background-color #dedede;
      border: 1px solid #bebebe;
    }
    &.solded {
      background-color #f3625e;
      border-color: #f3625e;
    }
    &.opacity0 {
      opacity: 0;
    }
  }

  .ticket-info {
    height: 200px;
    .legend-wrap {
      padding: 10px 10px 0 10px;
      display: flex;
      justify-content: space-around;
      font-size: 14px;
      line-height: 24px;
      color: #979797;
      li {
        &::before {
          content: '';
          float: left;
          width: 23px;
          height: 23px;
          border-radius: 3px;
          margin-right: 5px;
          box-sizing: border-box;
        }
        &.unchecked::before {
          background-color #ffffff;
          border: 1px solid #eeeeee;
        }
        &.checked::before {
          background-color #0ec300;
          border: 1px solid #0ec300;
        }
        &.solded::before {
          background-color #f3625e;
          border-color: #f3625e;
        }
        &.disabled::before {
          background-color #dedede;
          border: 1px solid #bebebe;
        }
      }
    }
  }

  .activity-wrap {
    background-color: #ffffff;
    margin: 10px;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    padding: 10px;
    .header {
      &::after {
        content: '';
        display: block;
        clear: both;
      }
      .title {
        float: left;
        font-size: 1.6em;
        color: #333333;
        letter-spacing: 2px;
      }
      .el-icon-arrow-down {
        float: right;
        color: #5078f8;
        font-size: 1.6em;
        transition: transform .15s;
        &.expand {
          transition: transform .2s;
          transform: rotate(180deg);
        }
      }
    }
    .info {
      font-size: 1.1em;
      color: #666666;
      margin-top: 5px;
      span {
        padding-right: 6px;
      }
    }

    .check-seat-wrap {
      box-sizing: border-box;
      max-height: 0;
      overflow: hidden;

      background-image: linear-gradient(45deg, #dedede, #dedede);
      background-size: 100% 1px;
      background-repeat: no-repeat;
      background-position: 0 8px;
      padding-top: 0;

      transition: all .2s;
      &.expand {
        max-height: 200px;
        min-height: 72px;
        padding-top: 16px;
      }
      .check-seat-scroll-wrap {
        max-height: 184px;
        overflow: auto;
        // width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .seat-ticket {
        margin-bottom: 5px;
        min-width: calc(50% - 38px);
        position: relative;
        background-color: #f5f9fe;
        border-radius: 8px;
        padding: 4px 25px 4px 8px;
        .title {
          font-size: 1.2em;
          color: #333333;
        }
        .price {
          font-size: 1.2em;
          color: #ff8938;
          font-weight: bold;
        }
        .el-icon-close {
          position: absolute;
          font-size: 1.6em;
          right: 4px;
          top: calc(50% - 10px);
          color: #d3d5d8;
        }
      }
    }

  }
  .el-button--warning {
    background-color: #f98230;
    border-color: #f98230;
    color: #ffffff;
    display: block;
    margin: 10px 10px 20px 10px;
    width: calc(100% - 20px);
    font-weight: bold;
    &[disabled="disabled"] {
      color: #f9b56e;
    }
  }
  .el-button--warning .origin-price{
    font-size: 12px;
    color: #cccccc;
    text-decoration: line-through;
  }
}
</style>
