const setGesture = (el, options = {}) => {
  var obj = {}; //定义一个对象
  var istouch = false;
  var start = [];
  var scale = 1;
  var absScale = 1;

  var minScale = options.minScale || 0.5;
  var maxScale = options.maxScale || 2;

  el.addEventListener(
    "touchstart",
    e => {
      if (e.touches.length >= 2) {
        scale = scale >= maxScale ? maxScale : scale;
        scale = scale <= minScale ? minScale : scale;
        absScale = scale;
        //判断是否有两个点在屏幕上
        istouch = true;
        start = e.touches; //得到第一组两个点
        obj.gesturestart && obj.gesturestart.call(el); //执行gesturestart方法
      }
    },
    false
  );
  document.addEventListener(
    "touchmove",
    e => {
      e.preventDefault();
      if (e.touches.length >= 2 && istouch) {
        var now = e.touches; //得到第二组两个点
        scale =
          absScale -
          (1 - getDistance(now[0], now[1]) / getDistance(start[0], start[1]));

        scale = scale >= maxScale ? maxScale : scale;
        scale = scale <= minScale ? minScale : scale;

        // scale =
        // +scale !== 1
        //   ? getDistance(now[0], now[1]) / getDistance(start[0], start[1])
        //   : scale; //得到缩放比例，getDistance是勾股定理的一个方法
        let position = {};
        var rotation = getAngle(now[0], now[1]) - getAngle(start[0], start[1]); //得到旋转角度，getAngle是得到夹角的一个方法
        position.scale = scale.toFixed(2);
        position.rotation = rotation.toFixed(2);
        obj.gesturemove && obj.gesturemove.call(el, position); //执行gesturemove方法
      }
    },
    false
  );
  document.addEventListener(
    "touchend",
    () => {
      if (istouch) {
        istouch = false;
        obj.gestureend && obj.gestureend.call(el); //执行gestureend方法
      }
    },
    false
  );
  return obj;
};
const getDistance = (p1, p2) => {
  var x = p2.pageX - p1.pageX,
    y = p2.pageY - p1.pageY;
  return Math.sqrt(x * x + y * y);
};
const getAngle = (p1, p2) => {
  var x = p1.pageX - p2.pageX,
    y = p1.pageY - p2.pageY;
  return (Math.atan2(y, x) * 180) / Math.PI;
};

export { setGesture };
