var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"cover-wrapper",attrs:{"element-loading-text":"场馆刷新中"},on:{"touchend":function($event){_vm.moving = false}}},[_c('el-button',{staticClass:"back-button",staticStyle:{"position":"absolute","z-index":"2"},attrs:{"type":"text","icon":"el-icon-arrow-left"},on:{"click":function($event){return _vm.$router.replace({ name: 'BuyTicket', query: _vm.$route.query })}}}),(_vm.showMiniMap)?_c('div',{staticClass:"mini-map"},[_c('div',{ref:"miniMap",staticClass:"mini-map-wrap",style:(`height: ${_vm.mapHeight + 2}px`)},[_c('div',{staticClass:"seat-box",style:(`width: ${_vm.containerWidth}px; height: ${_vm.containerHeight}px; transform: scale(${_vm.widthCalc});transform-origin: 0 0;zoom: 1;`)},_vm._l((_vm.arr),function(item,i){return _c('ul',{key:i,staticClass:"rows",class:[{ side: item.isSide }],style:(`background-color: ${!item.isSide && _vm.areaColors[item.area]}`)},[_vm._l((item.cols),function(item2,i2){return [(!item2.isSide)?_c('li',{key:i2,staticClass:"seat-item",class:{
                checked: item2.checked,
                unchecked: !item2.checked,
                disabled: item2.disabled,
                solded: item2.solded,
                opacity0: !item2.seatId
              }}):_c('li',{key:i2,staticClass:"side"})]})],2)}),0)]),_c('div',{staticClass:"loop",style:(`width: ${(_vm.scaleWidth * _vm.mapWidth) / _vm.zoom}px;
        height: ${(_vm.scaleHeight * _vm.mapHeight) / _vm.zoom}px;
        margin-left: ${(_vm.scrollLeft * _vm.scaleMoveX) / _vm.zoom}px;
        margin-top: ${(_vm.scrollTop * _vm.scaleMoveY) / _vm.zoom}px;`),on:{"touchstart":_vm.touchstartHandle,"touchmove":_vm.touchmoveHandle}})]):_vm._e(),_c('div',{staticClass:"venue-box"},[_c('div',{ref:"bayNumber",staticClass:"bay-number"},_vm._l((_vm.arr),function(item,i){return _c('div',{key:i,staticClass:"bay",class:{ side: item.isSide },style:(`zoom: ${_vm.zoom}`)},[_vm._v(" "+_vm._s(item.isSide ? "" : _vm.getIndex(i))+" "),(item.isSide)?_c('span',[_vm._v(_vm._s(item.area)+"："+_vm._s(item.number.value))]):_vm._e()])}),0),_c('div',{ref:"seatWrap",staticClass:"seat-wrap",on:{"scroll":_vm.scrollHandle}},[_c('div',{ref:"seatBox",staticClass:"seat-box",style:(`width: ${_vm.containerWidth}px; height: ${_vm.containerHeight}px; zoom: ${_vm.zoom}`),attrs:{"id":"seatBox"}},[_vm._l((_vm.arr),function(item,i){return [_c('ul',{key:i,staticClass:"rows",class:[{ side: item.isSide }],style:(`background-color: ${!item.isSide && _vm.areaColors[item.area]}`)},[_vm._l((item.cols),function(item2,i2){return [(!item2.isSide)?_c('li',{key:i2,staticClass:"seat-item",class:{
                  checked: item2.checked,
                  unchecked: !item2.checked,
                  disabled: item2.disabled,
                  solded: item2.solded,
                  opacity0: !item2.seatId
                },on:{"click":function($event){return _vm.seatChangeHandle(item2)}}}):_c('li',{key:i2,staticClass:"side"})]})],2)]})],2)])]),_c('div',{staticClass:"ticket-info"},[_vm._m(0),_c('div',{staticClass:"activity-wrap"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.activity.name))]),_c('i',{staticClass:"el-icon-arrow-down",class:{
            expand: _vm.isExpand
          },on:{"click":function($event){_vm.isExpand = !_vm.isExpand}}})]),_c('div',{staticClass:"info"},[(_vm.activity.metadata)?_c('span',[_vm._v(_vm._s(_vm.activity.metadata.signInStartTime)+"-"+_vm._s(this.activity.metadata.signInEndTime))]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.activity.address))])]),_c('div',{staticClass:"check-seat-wrap",class:{ expand: _vm.isExpand }},[_c('div',{staticClass:"check-seat-scroll-wrap"},_vm._l((_vm.checkedList),function(item,i){return _c('div',{key:i,staticClass:"seat-ticket",on:{"click":function($event){item.checked = false}}},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item.area)+" "+_vm._s(item.row)+"排"+_vm._s(item.col)+"座 ")]),_c('div',{staticClass:"price"},[_vm._v("￥ "+_vm._s(item.price))]),_c('i',{staticClass:"el-icon-close"})])}),0)])]),(_vm.checkedList.length)?_c('el-button',{attrs:{"type":"warning","round":""},on:{"click":_vm.commitHandle}},[_vm._v("￥"+_vm._s(_vm.totalPrice)+" "),_c('span',{staticClass:"origin-price"},[_vm._v("￥"+_vm._s(_vm.originPrice))]),_vm._v(" 确认选座")]):_c('el-button',{attrs:{"type":"warning","disabled":"","round":""}},[_vm._v("请先选座")])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"legend-wrap"},[_c('li',{staticClass:"unchecked"},[_vm._v("可选")]),_c('li',{staticClass:"checked"},[_vm._v("已选")]),_c('li',{staticClass:"solded"},[_vm._v("已售")]),_c('li',{staticClass:"disabled"},[_vm._v("不可选")])])
}]

export { render, staticRenderFns }